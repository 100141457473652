<template>
<div style="background: #f0f2f5;width:100%;min-height:100vh;overflow:hidden;">
  <div class='list-container'>
    <div class='list-top'>
      系统目前仅支持下载最新导出的10条数据；刷新这个页面，获取文件生成结果
    </div>
    <div class='list-record' v-loading='loading'>
      <el-card class='item' v-for='(item,i) in exportList' :key="i">

        <div slot="header" class="clearfix">
          <span>订单导出时间：{{item.AddTime}}</span>
          <span style='margin-left:50px;'>操作人：{{item.OperatorName}}（{{item.OperatorPhone}}）</span>
          <span style='margin-left:50px;'>导出维度：
            {{item.ExportDimension==0?'按商品明细导出':(item.ExportDimension==1?'按订单导出':'按商品明细合并地址导出')}}
          </span>
        </div>

        <div class='item-content' v-if="item.Type==0">
          <div class='item-time'>
            <span v-if="item.QueryTermsDic.下单时间!=''">下单时间：{{item.QueryTermsDic.下单时间}}</span>
            <span v-if="item.QueryTermsDic.发货时间!=''">发货时间：{{item.QueryTermsDic.发货时间}}</span>
            <span v-if="item.QueryTermsDic.完成时间!=''">完成时间：{{item.QueryTermsDic.完成时间}}</span>
            <span v-if="item.QueryTermsDic.支付时间!=''">支付时间：{{item.QueryTermsDic.支付时间}}</span>
          </div>
          <div class='item-belong'>
            <span style="margin-right:30px" v-if="mallInfo.IsMoreShop && item.QueryTermsDic.支付业绩归属">支付业绩归属：{{item.QueryTermsDic.支付业绩归属}}</span>
            <span  style="margin-right:30px" v-if="mallInfo.IsMoreShop && item.QueryTermsDic.结算业绩归属">结算业绩归属：{{item.QueryTermsDic.结算业绩归属}}</span>
          </div>
          
          <div class='item-status'>
            <div  class="item-one" v-if="item.QueryTermsDic.配送方式!=''">配送方式：{{item.QueryTermsDic.配送方式}}</div>
            <div  class="item-one" v-if="item.QueryTermsDic.退款信息!=''">退款信息：{{item.QueryTermsDic.退款信息}}</div>
            <div  class="item-one" v-if="item.QueryTermsDic.最终提货门店!=''  && mallInfo.IsMoreShop">
              <span >最终提货门店：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:183px;">{{item.QueryTermsDic.最终提货门店}}</span>
            </div>
            <div  class="item-one" v-if="item.QueryTermsDic.提货门店!=''  && mallInfo.IsMoreShop">提货门店：{{item.QueryTermsDic.提货门店}}</div>
            <div  class="item-one" v-if="item.QueryTermsDic.是否加星!=''">是否加星：{{item.QueryTermsDic.是否加星}}</div>
            <div  class="item-one" v-if="item.QueryTermsDic.订单类型!=''">
              <span>订单类型：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:220px;">{{item.QueryTermsDic.订单类型}}</span>
            </div>
            <div  class="item-one"  v-if="item.QueryTermsDic.订单状态!=''">
              <span>订单状态：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:220px;">{{item.QueryTermsDic.订单状态}}</span>
            </div>
            <div  class="item-one"  v-if="item.QueryTermsDic.支付方式!=''">
              <span>支付方式：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:220px;">{{item.QueryTermsDic.支付方式}}</span>
            </div>
            <div  class="item-one"  v-if="item.QueryTermsDic.支付方式!=''">
              <span>订单来源：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:220px;">{{item.QueryTermsDic.订单来源}}</span>
            </div>

          </div>
        </div>
        <div class='item-content' v-else>
          <div class='item-time'>
            <span v-if="item.QueryTermsDic.下单时间!=''">下单时间：{{item.QueryTermsDic.下单时间}}</span>
          </div>
          <div class='item-status'>
            <div  class="item-one"  v-if="item.QueryTermsDic.订单状态!=''">
              <span>订单状态：</span>
              <span style="vertical-align: top;line-height:30px;display:inline-block;width:220px;">{{item.QueryTermsDic.订单状态}}</span>
            </div>
          </div>
        </div>
        <el-button v-if="item.FileName!=''&&item.ExportState == 2"  class='item-button' type="primary" @click='handleExport(item)'>
          下载订单报表
        </el-button>
        <div v-else  class='item-button item-button-unload'   :style="{'display':item.ExportState == 2?'none':'inline-block'}" >
          {{item.ExportState == 0 ? '文件生成中' : item.ExportState == 1 ?'文件生成失败':'' }}
        </div>
        <el-button class="item-button" @click="lookrecord(item)">下载记录</el-button>


      </el-card>
    </div>
    <el-pagination v-if="page.total"  style="margin:20px 0;float:right;" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total">
    </el-pagination>
  </div>

  <el-dialog :visible.sync="recordShow" title="订单报表下载记录" custom-class="dialog-body-paddingTop-10" width="500px" @close="closerecord">
    <el-table :data="recordlist" v-loading="tableloading">
      <el-table-column prop="" label="下载人">
        <template slot-scope="scope">
          <div>
            <div class="ellipsis">{{scope.row.EmployeeName}}</div>
            <div>{{scope.row.Phone}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="AddTime" label="下载时间" width="160"></el-table-column>
    </el-table>
    <div v-if="record.total"  style="margin-top:10px;text-align:right">
      <el-pagination
        @current-change="handleCurrentChange2" :current-page="record.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="record.size"
        layout="total, prev, pager, next, jumper" :total="record.total">
      </el-pagination>
    </div>
  </el-dialog>

  <el-dialog :visible.sync="codeShow" title="验证手机号" custom-class="dialog-body-paddingTop-10" width="400px">
    <div style="color:#999999">为了信息安全，请输入发送至{{rowmsg.OperatorPhone}}的4位验证码。</div>
    <div class="inputbox">
      <el-input placeholder="请输入验证码" class="input" v-model="Code"></el-input>
      <el-button type="text" style="margin-left:10px" :style="{color:num==60?'#409eff':'#909399'}" :disabled="num!=60"
       @click="getsms">{{num==60?'获取验证码':`${num}s`}}</el-button>
    </div>
    <div slot="footer">
      <el-button @click="codeShow=false,Code=''">取消</el-button>
      <el-button type="primary" @click="checkcode">确认</el-button>
    </div>
  </el-dialog>

  <el-dialog :visible.sync="showImgCode" width="540px" title="请先完成图片验证" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form ref="form" label-width="110px">
      <el-form-item label="图片验证码:">
        <div class="form-wraper">
          <el-input v-model="ImgCode" style="width: 200px;"></el-input>
          <img :src="imgCodeUrl">
        </div>
        <div class="form-tip">
          <el-link type="primary" @click="getImgCode">看不清？换一张</el-link>
        </div>
      </el-form-item>
    </el-form>
    
    <div slot="footer" class="dialog-footer">
          <el-button @click="showImgCode = false;ImgCode=''">取 消</el-button>
          <el-button type="primary" @click="confirmsms">确 认</el-button>
        </div>
  </el-dialog>
</div>
</template>
<script>
import apiList from '@/api/other'
import config from '@/config/index'
import {
  mapGetters
} from "vuex"
export default {
  computed:{
    ...mapGetters([
      'mallInfo','mallPhone'
    ])
  },
  data(){
    return {
      loading:false,
			exportUrl: config.EXPORT_URL,
      exportList:[],
      page:{
        total: 0,
        current: 1,
        size: 20
      },
      recordShow:false,
      recordlist:[],
      tableloading:false,
      record:{
        total: 0,
        current: 1,
        size: 5
      },
      rowmsg:{},
      codeShow:false,
      time:{},
      num:60,
      Code:'',
      ImgCode:'',
      showImgCode:false,
      imgCodeUrl:''
    }
  },
  beforeCreate(){
  },
  created(){
    // console.log(this.mallInfo,'111')
    this.getData()
  },
  methods:{
    async checkcode(){
      if(!this.Code){
        this.$message.error('请输入短信验证码')
        return
      }
      try{
        let res = await apiList.downloadverifycode({
          Phone:this.rowmsg.OperatorPhone,
          SmsCode:this.Code,
        })
        if(res.IsSuccess){
          this.codeShow = false
          this.exportfile()
        }
      }finally{
        //
      }
    },
    confirmsms(){
      if(!this.ImgCode){
        this.$message.error('请输入图片验证码')
        return
      }
      this.getsms()
    },
    async getImgCode(){
      try{
        let data = {
          Phone: this.rowmsg.OperatorPhone
        }
        let res = await apiList.downloadsendsmsimgcode(data)
        if (res.img) {
          this.imgCodeUrl = res.img;
        }
      }finally{
        //
      }
    },
    async getsms(){
      try{
        let res = await apiList.downloadsendsmscode({
          Phone:this.rowmsg.OperatorPhone,
          ImgCode:this.ImgCode,
          noError: true,
        })
        // res.ErrorCode = 1000060
        // res.IsSuccess = false
        if(res.IsSuccess){
          this.countdown()
          this.showImgCode = false
          this.$message.success('验证码发送成功')
        } else if (res.ErrorCode == 1000060) {
          this.showImgCode = true;
          this.$message.error(res.Message);
          this.getImgCode();
        } else {
          this.$message.error(res.Message);
          this.getImgCode();
        }
      }finally{
        //
      }
    },
    countdown(){
      if(this.num<0){
        this.num = 60
        return 
      }
      setTimeout(() => {
        this.num -- 
        this.countdown()
      }, 1000);
    },
    lookrecord(item){
      this.rowmsg = item
      this.recordShow = true
      this.getrecord()
    },
    closerecord(){
      this.record = {
        total: 0,
        current: 1,
        size: 5
      }
      this.recordShow = false
    },
    async getrecord(){
      this.tableloading = true
      try{
        let res = await apiList.downloadrecordlist({
          Id:this.rowmsg.Id,
          Skip:(this.record.current-1)*this.record.size,
          Take:this.record.size,
        })
        if(res.IsSuccess){
          this.recordlist = res.Result.Results
          this.record.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleCurrentChange2(e){
      this.record.current = e
      this.getrecord()
    },
    async getData(){
      this.loading = true
      try{
        let result = await apiList.orderExportRecordIndex({
          Skip: (this.page.current - 1) * this.page.size, 
          Take: this.page.size, 
        })
        this.page.total = result.Result.Total

        let data = result.Result.Results
        // console.log(data)
        this.exportList = data.filter(t=>{
          let QueryTermsDic = Object.getOwnPropertyNames(t.QueryTermsDic);
          return QueryTermsDic.length>0
        })
      } catch(e){
        this.loading = false
      }finally{
        this.loading = false
      }
    },
    async handleExport(item){
      this.rowmsg = item
      try{
        let res = await apiList.isneedsmsverify()
        if(res.IsSuccess){
          if(res.Result.IsNeedSmsVerify){
            this.codeShow = true
          }else{
            this.exportfile()
          }
        }
      }finally{
        //
      }
    },
    exportfile(){
      // console.log(this.rowmsg)
      let url = this.exportUrl + '/pc/order-export-record/download?' + 
      '&FileName=' + this.rowmsg.FileName + '&Id=' + this.rowmsg.Id
      window.open(url);
    },
    handleSizeChange(val){
      this.page.size = val
      this.page.current = 1
      this.getData()
    },
    handleCurrentChange(val){
      this.page.current = val
      this.getData()
    }
  }

}
</script>
<style lang='less' scoped>
.form-wraper{
		display: flex;
		align-items: center;
		
		img{
			display: block;
			width: 130px;
			margin-left: 10px;
		}
	}
	.form-tip{
		margin-left: 228px;
		line-height: 20px;
		margin-top: 5px;
	}
.inputbox{
  display: flex;
  align-items: center;
  margin-top: 20px;
  .input{
    ::v-deep .el-input__inner{
      border: none;
    }
  }
}
.list-container{
  width:1000px;
  overflow: hidden;
  margin:0 auto;
  .list-top{
    margin:20px 0;
    padding:0 20px;
    background:#FDF6EC;;
    color:#FDA23C;
    border:1px solid #FDA23C;
    border-radius:2px;
    line-height:50px;
  }
  .list-record{
    width:100%;
    .item{
      overflow: hidden;
      margin-bottom: 20px;
      border-radius:2px;
      background:#fff;
      .item-top{
        padding:0 20px;
        background:#ddd;
        line-height:40px;
      }
      .item-content{
        padding:0 20px;
        line-height:30px;
        // }
        .item-status{
          .item-one{
            vertical-align: top;
            display: inline-block; 
            width:33%;
            margin-top: 10px;
          }
        }
      }
      .item-button{
        float: right;
        margin: 20px 20px 20px 0;
      }
      .item-button-unload{
        background: #FFF;
        border: 1px solid #DCDFE6;
        border-radius: 4px;color: #606266;
        padding: 10px 20px;font-size: 14px;
      }
    }
  }
  ::v-deep .el-card__header{
    padding:0 0;
    
    background-color: #e3e3e3;
  }
  .clearfix{
    padding: 18px 20px;
  }
}
</style>